<template>
  <v-card
    v-bind="$attrs"
    data-cy="product-card"
    v-on="$listeners"
  >
    <v-card-subtitle class="pb-0">
      {{ companyName }}
    </v-card-subtitle>
    <v-card-title>
      <core-element-product-info
        :contract="contract"
        :has-button="false"
      />
    </v-card-title>
    <v-divider />
    <v-container
      fluid
      class="px-4 pt-1"
    >
      <material-product-details-card-body />
      <v-divider class="my-2" />
      <material-product-details-card-footer />
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  computed: {
    contract () {
      const routeParams = this.$route.params
      return this.$store.getters['contract/contract']({
        clientId: routeParams.clientId,
        contractId: routeParams.contractId
      })
    },
    companyName () {
      return this.client.billingDetails &&
        this.client.billingDetails.billing &&
        this.client.billingDetails.billing.company
        ? this.client.billingDetails.billing.company
        : 'Unknown company'
    }
  }
}
</script>
